import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from '../../../resources/custom/images/about-us.jpg';
import CustomerTestimonial from '../../components/shared/CustomerTestimonial';

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"}  />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About-Us" parent="Home"/>

      <section className="about-page section-big-py-space">
        <div className="custom-container">
          <Row>
            <Col lg="6">
              <div className="banner-section">
                <Media src={aboutImg} className="img-fluid w-100" alt="" />
              </div>
            </Col>
            <Col lg="6">
              <h4>About our Store</h4>
              <p className="mb-2">
                {" "}
                Welcome to <b>cops services store</b> , your one-stop destination for premium policing Products.
              </p>
              <p className="mb-2">
                {" "}
                Our curated collection offers everything that law enforcement 
agencies need, from tactical gear to safety equipment, and from Accessories to tools. With a commitment of quality and reliability, we ensure that each item meets the highest standards of performance and durability. Whether you’re in need of duty gear, uniforms, or specialized tools, we’ve got you equipped. Click on shop now with the confidence knowing that you’re getting top notch products that are designed to support and protect, those who serve and protect.
Come experience the difference at Cop services store today!
              </p>
              <p className="mb-2">
                {" "}
               
                At <b>cops services store</b>, we are dedicated to providing our customers with the highest level of service and satisfaction. Our team works tirelessly to ensure that our website is up-to-date with the latest products, prices, and promotions. We also strive to make the shopping process as easy and convenient as possible for our customers.
              </p>
              <p>
                {" "}
                We are committed to delivering the highest quality products from the most reliable suppliers at competitive prices. 
                We believe in transparency and honesty in all our <b>business dealings</b>, and we take pride in delivering products that meet our customers' expectations..
              </p>
            </Col>
          </Row>
        </div>
      </section>

      {/* <CustomerTestimonial /> */}

    </>
  );

}

export default About;
